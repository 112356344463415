<template>
  <div id="app">
    <div id="particles-js"></div> <!-- 粒子效果背景 -->
    <div class="container">
      <div class="row">
        <div class="col">
          <img :src="logoUrl" alt="Logo" class="logo mb-4" />
          <h1 class="main-title">Kurisu</h1>
          <p class="sub-title">一个基于口头描述的文件格式转换工具。</p>
          <a href="https://www.bilibili.com/video/BV19H4y1A726?vd_source=4d155b95faa7ba0d1a819c3b8ea2562c"><button class="download-btn">查看演示</button></a>
          <button class="download-btn" @click="download">下载</button>
          <a href="https://www.github.com/mcdfsteve/kurisu"><button class="download-btn">访问 Github</button></a>
          <a href="https://store.steampowered.com/app/3034460/Kurisu/"><button class="download-btn">访问 Steam</button></a>
          <div class="scroll-down-arrow" v-if="showArrow"></div>
        </div>
      </div>
    </div>
    <div class="details">
      <!-- 第一页内容 -->
      <div class="page">
        <div class="detail-item">
          <p>如果你经常处理多媒体文件，却对传统的视频和音频编辑软件的复杂性感到头疼，那么 Kurisu 可能正是你需要的解决方案。这款由 AimesSoft 开发的软件于 2024 年 7 月发布，并已在 Steam 上架。Kurisu 通过将自然语言处理与强大的 FFmpeg 框架相结合，为多媒体处理带来了创新的体验。</p>
        </div>
      </div>

      <!-- 第二页内容 -->
      <div class="page">
        <div class="detail-item">
          <h2>什么是 Kurisu？</h2>
          <hr />
          <p>Kurisu 是一款可以通过简单的文本描述来执行音频、视频和图像处理任务的软件。与传统的编辑软件不同，Kurisu 的核心优势在于其极简的操作方式。你只需要输入简单的指令，比如“把这个搞笑的视频片段转换成 GIF”，Kurisu 就能在后台完成所有操作，而无需你手动调节复杂的设置。</p>
        </div>

        <div class="detail-item">
          <h2>Kurisu 的主要功能</h2>
          <hr />
          <p>·多功能处理：Kurisu 支持音频、视频和图像的编辑与转码，几乎所有 FFmpeg 能完成的任务它都可以实现。无论是旋转、调整分辨率、格式转换，还是剪辑片段、添加字幕，Kurisu 都能轻松应对。<br>
            · 自然语言输入：只需在对话框中输入你的需求，Kurisu 会自动生成并执行相应的 FFmpeg 命令。这种方式极大简化了多媒体文件的处理过程，让非专业用户也能轻松上手。<br>
            · 批量处理：Kurisu 支持批量处理功能，你可以一次选择多个文件进行处理，极大提高了工作效率。</p>
        </div>

        <div class="detail-item">
          <h2>Kurisu 的优势与限制</h2>
          <hr />
          <p>尽管 Kurisu 在简化操作上表现出色，但它也有一些限制。由于其依赖 FFmpeg 作为核心引擎，因此它在功能上无法超越 FFmpeg 的能力。此外，Kurisu 并不具备专业视频编辑软件的可视化预览功能，这意味着在处理复杂剪辑时，可能需要配合其他播放器使用。Kurisu 的设计初衷是为用户提供一个更为便捷的多媒体处理工具，而不是取代那些功能强大的专业编辑软件。如果你的需求主要是简单的转换和编辑操作，Kurisu 将是一个非常高效的选择。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { initParticlesJS } from '../particles';
import './HeroSection.css';

export default {
  name: 'HeroSection',
  setup() {
    const logoUrl = require('@/assets/logo.png');
    const showArrow = true;

    const download = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      let downloadLink;

      // 检测操作系统
      if (userAgent.indexOf('win') !== -1) {
        // Windows
        downloadLink = 'https://dfsteve.top/wp-content/kod/?explorer/index/fileDownload&path=%7Bsource%3A287%7D%2F&accessToken=3149OF-jDmJfGzJrFJn7ihuK_aczvjzAMWDk0X3fgA1H90Oyefn77wZ8tgSOtgB-Q6kxy4r5k6IvOYIpBg&download=1&_etag=1725276642-107868461';
      } else if (userAgent.indexOf('mac') !== -1) {
        // macOS
        downloadLink = 'https://dfsteve.top/wp-content/kod/?explorer/index/fileDownload&path=%7Bsource%3A286%7D%2F&accessToken=3149OF-jDmJfGzJrFJn7ihuK_aczvjzAMWDk0X3fgA1H90Oyefn77wZ8tgSOtgB-Q6kxy4r5k6IvOYIpBg&download=1&_etag=1725276411-207627176';
      } else if (userAgent.indexOf('linux') !== -1) {
        // Linux
        downloadLink = 'https://dfsteve.top/wp-content/kod/?explorer/index/fileDownload&path=%7Bsource%3A285%7D%2F&accessToken=3149OF-jDmJfGzJrFJn7ihuK_aczvjzAMWDk0X3fgA1H90Oyefn77wZ8tgSOtgB-Q6kxy4r5k6IvOYIpBg&download=1&_etag=1725276750-135790893';
      } else {
        // 其他操作系统
        alert('抱歉，您的操作系统不受支持。');
        return;
      }

      // 跳转到下载链接
      window.location.href = downloadLink;
    };

    onMounted(() => {
      setTimeout(() => {
        initParticlesJS();
      }, 100);
    });

    return { logoUrl, showArrow, download };
  },
};
</script>